<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="logo-header"><ion-img src="/assets/logo.png"></ion-img></div>
      <div class="login-container">
        <h3>Login</h3>
        <ion-input
          style="margin-top: 1em"
          autocomplete="email"
          inputmode="email"
          type="email"
          v-model="formData.email"
          class="form-control"
          placeholder="email address"
        />
        <ion-input
          type="password"
          v-model="formData.password"
          class="form-control"
          placeholder="password"
        />
        <p>
          <a href="#" @click.stop.prevent="forgotPassword">Forgot Password</a>
        </p>
        <ion-button color="primary" size="large" @click="signIn"
          >Log In</ion-button
        >
        <router-link href="/sign-up" :to="'/sign-up'"
          >Don't have an account? Register</router-link
        >
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import {
  IonPage,
  IonContent,
  IonButton,
  IonInput,
  IonImg,
  actionSheetController,
  toastController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { send, close } from "ionicons/icons";

export default defineComponent({
  name: "SignIn",
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonInput,
    IonImg,
  },
  setup() {
    return {};
  },

  data() {
    return {
      formData: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    signIn() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.formData.email, this.formData.password)
        .then(() => {
          // this.$router.replace("/tabs/home");
        })
        .catch((e) => {
          alert(e.message);
        });
    },
    forgotPassword() {
      if (this.formData.email.length > 0) {
        actionSheetController
          .create({
            header: "Reset Password?",
            cssClass: 'action-sheet',
            buttons: [
              {
                text: "Send",
                icon: send,
                handler: () => {
                  firebase
                    .auth()
                    .sendPasswordResetEmail(this.formData.email)
                    .finally(() => {
                      actionSheetController.dismiss();
                      toastController
                        .create({
                          message: "Password reset was sent",
                          duration: 2000,
                        })
                        .then((toast) => toast.present());
                    });
                },
              },
              {
                text: "Cancel",
                icon: close,
                role: "cancel",
              },
            ],
          })
          .then((actionsheet) => {
            actionsheet.present();
          });
      } else {
        toastController
          .create({
            message: "Please enter your email",
            duration: 2000,
          })
          .then((toast) => toast.present());
      }
    },
  },
});
</script>
<style scoped>
h3 {
  color: #fff;
}
</style>